import Chart from "chart.js/auto";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";

let charts = {};
charts.graficoConversaoCriadores = {};
charts.graficoFirst = {};
charts.graficoSecond = {};
charts.graficoThird = {};
charts.graficoChurn = {};
charts.graficoHistoricoDeBriefing = {};
charts.graficoCriacaoPrimeiroBriefing = {};
charts.graficoTempoAprovacao = {};

export default {

  data() {
    return {
      meses: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Maio",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],

      approved: [

      ],

      estadoCreators: [
      ],

      firstPercentage: {
        percent: 8,
        color: "#7553E2"
      },
      secondPercentage: {
        percent: 13,
        color: "#74E7D0"
      },
      ethnicity: {
        white: 0,
        black: 0,
        indigenous: 0,
        asian: 0,
      },
      creator: '',
      company: '',
      men: 0,
      woman: 0,
      binary: 0,
      creators_approved: '',
      creators_percent: '',
      companies_approved: '',
      companies_percent: '',
      percent_creator: '',
      percent_company: '',
      year: 2022,
      total_account: '',
      total_genders: '',
      total_ethnicity: '',
      loading: true,
      loading_1: true,
      loading_2: true,
      loading_3: true,
      loading_4: true,
      tags: '',
      companies: {},
      professionals: {}

    };
  },

  mounted() {
    let _this = this;

    this.apiBriefings();
    this.apiCompanies();
    this.apiProfessionals();
    this.apiTags();
  },

  methods: {
    apiBriefings(){
        axios
        .get('/api/v1/analytics/briefings?year='+this.year, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
      .then(response => {
        this.loading_1 = false
        this.historicoDeBriefing(response)
        this.tempoDeAprovacaoBriefing(response)
      })
    },

    async apiCompanies(){
      let response = await axios
      .get('/api/v1/analytics/companies?year=' + this.year, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      });

      if(response) {
        this.loading_3 = false;
        this.companies = response.data
        this.tempoRealizacaoDash(response)
        this.criacaoPrimeiroBriefingDash(response)
        this.newCompanies(response);
        this.apiTags();
      }
    },

    async apiProfessionals(){
      let response = await axios
      .get('/api/v1/analytics/professionals?year=' + this.year, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      });
      
        if(response) this.loading = false;
        this.professionals = response.data
        this.conversaoCriadoresDash(response)
        this.creatorsApproved(response)
        this.stateCreators(response);
        this.churnChart(response)
        this.firstDoughnutCard()
    },

    async apiTags(){
      let response = await axios
      .get('/api/v1/analytics/tags?year=' + this.year, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      });

      if(response){
        this.loading_4 = false;
        this.tags = response.data
        this.secondDoughnutCard(response);
        this.thirdDoughnutCard(response);
      }
    },

    changeYear(y){
      this.loading = true;
      this.loading_1 = true;
      this.loading_3 = true;
      this.loading_4 = true;

      this.year = y
      this.destroy(charts.graficoSecond)
      this.destroy(charts.graficoThird)
      this.destroy(charts.graficoHistoricoDeBriefing)
      this.destroy(charts.graficoCriacaoPrimeiroBriefing)
      this.destroy(charts.graficoTempoAprovacao)
      this.destroy(charts.tempoRealizacaoDash)
      this.destroy(charts.graficoChurn)
      this.destroy(charts.graficoConversaoCriadores)
      this.destroy(charts.graficoFirst)
      this.estadoCreators = []

      this.apiBriefings();
      this.apiCompanies();
      this.apiProfessionals();
      this.apiTags();
      // this.graficoPrimeiroBriefing.destroy();     
    },

    historicoDeBriefing(response) {
      let opened = [];
      let approved = [];
      let canceled = [];
      for (var i = 0; i < 12; i++) {
        opened[i] = response.data.briefings.filter(b => parseInt(b.created_at.substr(5, 2)) == (i + 1)).length;
      }

      for (var i = 0; i < 12; i++) {
        approved[i] = response.data.briefings.filter(b => parseInt(b.created_at.substr(5, 2)) == (i + 1) && b.status == 4).length;
      }

      for (var i = 0; i < 12; i++) {
        canceled[i] = response.data.briefings.filter(b => parseInt(b.created_at.substr(5, 2)) == (i + 1) && b.status == 5).length;
      }    

      var ctx = document.getElementById("historicoDeBriefing");
      var historicoDeBriefing = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.meses,
          datasets: [{
              label: "Abertos",
              backgroundColor: " #74E7D0",
              data: opened,
            },
            {
              label: "Aprovados",
              backgroundColor: "#7553E2",
              data: approved,
            },
            {
              label: "Cancelados",
              backgroundColor: " #F2C94C",
              data: canceled,
            },
          ],
        },
        options: {
          plugins: {
            legend: false, // Hide legend
          },
        },
      });

      charts.graficoHistoricoDeBriefing = historicoDeBriefing
    },

    tempoRealizacaoDash(response) {
      var length = response.data.companies.length
      var companies = response.data.companies      
     
      var medias = [];
      let first = [];
      var meses = [
        [],[],[],[],[],[],[],[],[],[],[],[],
      ];
      var mes
      var date_company
      var date_hiring
      var dif_In_Time
      var dif

      function media(arr) {
        let soma = 0;
        
        if (arr.length == 0) return 0;
          for(let i in arr) {
          soma += arr[i]
        }
        return Math.round(soma/arr.length);
      }

      var hirings = companies.filter(c => c.hirings.length != 0)
      hirings.forEach(c => {       
        mes = parseInt(c.created_at.substr(5,2) - 1);

        date_company = new Date (c.created_at.substr(0,10)).getTime()
        date_hiring = new Date(c.hirings[0].created_at.substr(0,10)).getTime()

        dif_In_Time = date_hiring - date_company
        dif = dif_In_Time / (1000 * 3600 * 24);
      
        meses[mes].push(dif)
      });        
      
      for (var i = 0; i < meses.length; i++) {
        medias.push(media(meses[i]) )
      }      

      for (var i = 0; i < 12; i++) {       
        if(medias[i] == 0){
         medias[i] = null
        }
      }
      const ctx = document.getElementById("tempoRealizacaoDash");
      const data = {
        labels: this.meses,
        datasets: [{
          label: "Dias",
          data: medias,
          borderColor: "#7553E2",
          tension: 0.1,
        }, ],
      };
      const tempoRealizacaoDash = new Chart(ctx, {
        type: "line",
        data: data,
        options: {
          plugins: {
            legend: false, // Hide legend
          },
        },
      });

      charts.tempoRealizacaoDash = tempoRealizacaoDash
    },

    tempoDeAprovacaoBriefing(response) {
      var briefings = response.data.briefings
      var mediaFreela = [];
      var mediaCreators = [];
      var meses = [[],[],[],[],[],[],[],[],[],[],[],[]]
      var meses2 = [[],[],[],[],[],[],[],[],[],[],[],[]]


      function media(arr) {
        let soma = 0;
        
        if (arr.length == 0) return 0;
          for(let i in arr) {
          soma += arr[i]
        }
        return Math.round(soma/arr.length);
      }
      function formatDate(date){
        return date.replace(/-/g, '\/').replace(/T.+/, '')
      }

      var freela = briefings.filter(briefing => briefing.approved_at != null  && briefing.type_id == 1 && briefing.cancel_at == null);
      var creators = briefings.filter(briefing => briefing.approved_at != null && briefing.type_id == 2 && briefing.cancel_at == null);

      freela.forEach(b => {
        var approved = new Date(formatDate(b.approved_at)).getTime()
        var created = new Date(formatDate(b.created_at)).getTime()

        var mes = parseInt(b.approved_at.substr(5,2)) - 1
        var dif_In_Time =  approved - created
        var dif = dif_In_Time / (1000 * 3600 * 24);
        if(dif > 0){
          meses[mes].push(dif)
        }
       
      });
      for (var i = 0; i < meses.length; i++) {
        mediaFreela.push(media(meses[i]))
      }

      creators.forEach(b => {        
        var approved = new Date(formatDate(b.approved_at)).getTime()
        var created = new Date(formatDate(b.created_at)).getTime()
        
        var mes = parseInt(b.approved_at.substr(5,2)) -1

        var dif_In_Time = approved - created
        var dif = dif_In_Time / (1000 * 3600 * 24); 
        if(dif > 0){
          meses2[mes].push(dif)
        }
      });      

      for (var i = 0; i < meses2.length; i++) {
        mediaCreators.push(media(meses2[i]))
      }

      for (var i = 0; i < 12; i++) {       
        if(mediaCreators[i] == 0){
         mediaCreators[i] = null
        }
        if(mediaFreela[i] == 0){
          mediaFreela[i] = null
         }
      }

      const ctx = document.getElementById("tempoDeAprovacaoBriefing");
      const data = {
        labels: this.meses,
        datasets: [{
            label: "Dias",
            data: mediaCreators,
            borderColor: "#7553E2",
          },

          {
            label: "Dias",
            data:mediaFreela,
            borderColor: "#F2C94C",
          },
        ],
      };
      const tempoDeAprovacaoBriefing = new Chart(ctx, {
        type: "line",
        data: data,
        options: {
          plugins: {
            legend: false, // Hide legend
          },
        },
      });

      charts.graficoTempoAprovacao = tempoDeAprovacaoBriefing;
    },

    churnChart(response) {
      var clients = [];
      var creator = [];

      var creators_deleted = response.data.professionals.filter(c => c.deleted_at != null)
      var clients_deleted = response.data.professionals.filter(c => c.deleted_at != null && c.company_id != null)

      for (var i = 0; i < 12; i++) {                         
        clients[i] = clients_deleted.filter(c => parseInt(c.deleted_at.substr(5,2)) == (i + 1)).length;
      }

      for (var i = 0; i < 12; i++) {
        creator[i] = creators_deleted.filter(c => parseInt(c.deleted_at.substr(5,2)) == (i + 1)).length;
      }

      for (var i = 0; i < 12; i++) {       
        if(creator[i] == 0){
         creator[i] = null
        }
        if(clients[i] == 0){
          clients[i] = null
         }
      }

      const ctx = document.getElementById("churnChart");
      const data = {
        labels: this.meses,
        datasets: [{
            label: "Clientes",
            data: clients,
            borderColor: "#7553E2",
            tension: 0.1,
          },

          {
            label: "Criadores",
            data: creator,
            borderColor: "#F2C94C",
            tension: 0.1,
          },
        ],
      };

      const churnChart = new Chart(ctx, {
        type: "line",
        data: data,
        options: {
          plugins: {
            legend: false, // Hide legend
          },
        },
      });

      charts.graficoChurn = churnChart
    },

    conversaoCriadoresDash(response) {
      let completed = [];
      let hiring = [];

      for (var i = 0; i < 12; i++) {
        completed[i] = response.data.professionals.filter(c => parseInt(c.created_at.substr(5, 2)) == (i + 1)).length;
      }

      for (var i = 0; i < 12; i++) {
        hiring[i] = response.data.professionals.filter(c => parseInt(c.created_at.substr(5,2)) == (i + 1) && c.hirings.length > 0).length;
      }

      for (var i = 0; i < 12; i++) {       
        if(completed[i] == 0){
         completed[i] = null
        }
        if(hiring[i] == 0){
          hiring[i] = null
         }
      }      

      const ctx = document.getElementById("conversaoCriadoresDash");
      const data = {
        labels: this.meses,
        datasets: [{
            label: "Cadastros Completos",
            data: completed,
            borderColor: "#7553E2",
            tension: 0.1,
          },

          {
            label: "Contratados",
            data: hiring,
            borderColor: "#F2C94C",
            tension: 0.1,
          },
        ],
      };
      const conversaoCriadoresDash = new Chart(ctx, {
        type: "line",
        data: data,
        options: {
          plugins: {
            legend: false, // Hide legend
          },
        },
      });

      charts.graficoConversaoCriadores = conversaoCriadoresDash;
    },
    
    firstDoughnutCard() {
      let creator = 0;
      let company = 0;   

      creator = this.professionals.professionals.length;
      company = this.companies.companies.length;

      const ctx = document.getElementById("firstDoughnutCard");
      const data = {
        datasets: [{
          label: "My first Dataset",
          data: [company, creator],
          backgroundColor: ["#74E7D0", "#7553E2"],
        }, ],
      };

      this.creator = creator
      this.company = company
      this.total_account = company + creator

      const firstDoughnutCard = new Chart(ctx, {
        type: "doughnut",
        data: data,
      });
      charts.graficoFirst = firstDoughnutCard;
    },

    secondDoughnutCard(response) {
      response.data.specialities.forEach(element => {

        if(element.id == 335){
          this.men = element.total     
        } 

        if(element.id == 334){
          this.woman = element.total     
        }
        
        if(element.id == 333){
          this.binary = element.total     
        }

        this.total_genders = this.men + this.woman + this.binary
       
      });

      const ctx = document.getElementById("secondDoughnutCard");
      const data = {
        datasets: [{
          data: [this.men, this.woman, this.binary],
          backgroundColor: ["#7553E2", "#74E7D0", "#F2C94C"],
        }],
      };
      const secondDoughnutCard = new Chart(ctx, {
        type: "doughnut",
        data: data,
      });

      charts.graficoSecond = secondDoughnutCard;
    },

    thirdDoughnutCard(response) {
      response.data.specialities.forEach(element => {

        if(element.id == 279){
          this.ethnicity.black  = element.total     
        } 

        if(element.id == 336){
          this.ethnicity.asian = element.total     
        }
        
        if(element.id == 337){
          this.binary = element.total     
        }

        if(element.id == 277){
          this.ethnicity.indigenous = element.total     
        }

        this.total_ethnicity = this.ethnicity.white + this.ethnicity.black + this.ethnicity.indigenous + this.ethnicity.asian

       
      });

      const ctx = document.getElementById("thirdDoughnutCard");
      const data = {
        datasets: [{
          data: [
            this.ethnicity.white,
            this.ethnicity.black,
            this.ethnicity.indigenous,
            this.ethnicity.asian
          ],
          backgroundColor: [
            "#7553E2",  
            "#74E7D0",
            "#589FDD",
            "#F2C94C",
          ],
        }],
      };

      const thirdDoughnutCard = new Chart(ctx, {
        type: "doughnut",
        data: data,
      });
      charts.graficoThird = thirdDoughnutCard;
    },

    creatorsApproved(response){
      function formatPercent(p){
        var str = new String(p)
       return str.replace('-', '')
      }

      if(response) this.loading = false;
      var percent =(response.data.total_approved - response.data.total_approved_last_year) * 100 / response.data.total_approved 

      if(response.data.total_approved_last_year == 0){
        percent =(response.data.total_approved - response.data.total_approved_last_year) * 100 / response.data.total_approved 
      } else {
        percent =(response.data.total_approved - response.data.total_approved_last_year) * 100 / response.data.total_approved_last_year
      }

      this.creators_approved = response.data.total_approved
      this.creators_percent = Math.round(formatPercent(percent))+"%"
      
      if(this.creators_approved  < response.data.total_approved_last_year){
        this.percent_creator = "icon-number-red";
      } else{
        this.percent_creator = "icon-number";
      }
    },

    newCompanies(response){
      function formatPercent(p){
        var str = new String(p)
       return str.replace('-', '')
      }

      if(response.data.total_companies_last_year == 0){
        var percent =(response.data.total - response.data.total_companies_last_year) * 100 / response.data.total
      } else {
        var percent =(response.data.total - response.data.total_companies_last_year) * 100 / response.data.total_companies_last_year
      }
      this.companies_approved = response.data.total
      this.companies_percent = Math.round(formatPercent(percent))+"%"
      
      if(response.data.total_companies_last_year > response.data.total){
        this.percent_company = "icon-number-red"
      } else{
        this.percent_company = "icon-number"
      }
    },

    stateCreators(response){
      var total
      var array_states = []

      function count(array_verticais){
        total = array_verticais.length
        var current = null;
        var count = 0;
        var resultado = []
        for (var i = 0; i < array_verticais.length; i++) {
            if (array_verticais[i] != current) {
                if (count > 0) {
                  resultado.push({name: current, number: count, total: total});
                }
                current = array_verticais[i];
                count = 1;
            } else {
                count++;
            }
        }
        if (count > 0) {
          resultado.push({name: current, number: count, total: total});
        }
        
        resultado.sort(function (a, b) {
          if (a.number > b.number) {
            return -1;
          }          
        });

        var resultado_limit = resultado.slice(0,10)
        return resultado_limit
      }

      var professionals = response.data.professionals
        professionals.forEach(professional => { 
          if(professional.profile.state != null) {
          array_states.push(professional.profile.state.name)
          array_states.sort()
          }
      })

      if(array_states.length != 0){
        count(array_states).forEach(r =>{
          var percent = (r.number * 100)/ r.total
          this.estadoCreators.push({
            city: r.name,
            percent: percent,
            color: "#74E7D0",
            number: r.number
          })
        })
      } else {
        this.estadoCreators.push({
          city: "Sem estado!",
          percent: 10,
          color: "#74E7D0",
          number: 0
        })
      }
    },

    criacaoPrimeiroBriefingDash(response) {
      var medias = [];
      let meses = [[],[],[],[],[],[],[],[],[],[],[],[]]
      var hiring
      var hirings = []
      var mes 


      function media(arr) {
        let soma = 0;
        
        if (arr.length == 0) return 0;
          for(let i in arr) {
          soma += arr[i]
        }
        return Math.round(soma/arr.length);
      }
      function formatDate(date){
        return date.replace(/-/g, '\/').replace(/T.+/, '')
      }

      var companies = response.data.companies.filter(b => b.hirings.length != 0)
      companies.forEach(company => {
        mes = parseInt(company.hirings[0].created_at.substr(5,2)) -1
        var created = new Date(company.hirings[0].created_at.substr(0,10)).getTime()
       
        
        if(company.hirings[0].approved_at != null){
          var approved = new Date(company.hirings[0].approved_at.substr(0,10)).getTime()
        } else {
          var approved = new Date(company.hirings[0].created_at.substr(0,10)).getTime()
        }

        var dif_In_Time = approved - created
        var diff = dif_In_Time / (1000 * 3600 * 24); 

        meses[mes].push(diff)
      })

      for (var i = 0; i < meses.length; i++) {
        medias.push(media(meses[i]) )
      }

      for (var i = 0; i < 12; i++) {       
        if(medias[i] == 0){
         medias[i] = null
        }
      }

      const ctx = document.getElementById("criacaoPrimeiroBriefingDash");
      const data = {
        labels: this.meses,
        datasets: [{
          label: "Dias",
          data: medias,
          backgroundColor: "#7553E2",
          borderColor: "#7553E2",
          pointStyle: "circle",
          pointRadius: 5,
        }, ],
      };
      const criacaoPrimeiroBriefingDash = new Chart(ctx, {
        type: "line",
        data: data,
        options: {
          plugins: {
            legend: false, // Hide legend
          },
        },
      });

      charts.graficoCriacaoPrimeiroBriefing = criacaoPrimeiroBriefingDash;
    },

    destroy(graph){
      graph.destroy();
    }
  },
}; 